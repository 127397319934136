import React from "react";
import Layout from "../../components/layout/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import SEO from "../../components/seo";

const rebuild = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="SWA Symrise Project Rebuild"
          description="SWA and Symrise are aligned in the desire to assist women and children impacted by domestic violence and taking temporary refuge in shelter rebuild their lives for a better futures."
        />
        <section className="max-w-4xl px-2 mx-auto sm:w-4/5">
          <div className="relative py-16 overflow-hidden bg-white">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
              <div
                className="relative h-full mx-auto text-lg max-w-prose"
                aria-hidden="true"
              >
                <svg
                  className="absolute transform translate-x-32 top-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                  />
                </svg>
                <svg
                  className="absolute transform -translate-x-32 -translate-y-1/2 top-1/2 right-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                  />
                </svg>
                <svg
                  className="absolute transform translate-x-32 bottom-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="d3eb07ae-5182-43e6-857d-35c643af9034"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
                  />
                </svg>
              </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="mx-auto text-lg max-w-prose">
                <h1>
                  {/* <span className="block text-base font-semibold tracking-wide text-center text-indigo-600 uppercase">
                    Introducing
                  </span> */}
                  <span className="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-swa-1 sm:text-4xl">
                    Project Rebuild
                  </span>
                </h1>
                <h2 className="my-3 text-center">
                  Joint project by Singapore Women Association(SWA) and <br />
                  Symrise Asia Pacific Pte Ltd
                </h2>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  SWA and Symrise are aligned in the desire to assist women and
                  children impacted by domestic violence and taking temporary
                  refuge in shelter rebuild their lives for a better futures.
                </p>

                <div className="mx-auto mt-6 prose prose-lg text-gray-600 prose-indigo">
                  <h3 className="text-3xl font-bold ">Project Rebuild Roles</h3>
                  <ul>
                    <li className="list-none">
                      Symrise as corporate partner with cash donation funding
                    </li>
                    <li className="list-none">
                      SWA as coordinator leading the program and disbursement
                      for impact
                    </li>
                  </ul>
                  <h4>Outcome of the Project Rebuild</h4>

                  <p>
                    Moonshot: Singapore Women’s Association (SWA) and Symrise
                    Asia Pacific Pte Ltd (Symrise) to establish a long-term
                    collaboration to ensure continuity of Project Rebuild.
                  </p>

                  <h4>Project Rebuild progressive missions for 2022-2024</h4>
                  <div>
                    <span className="font-bold">
                      Empowering victims of domestic violence with a progressive
                      future
                    </span>
                    <br />
                    We have scoped out the SDG (Sustainable Development Goals)
                    goals which corporates can use for their alignment in CSR
                    (Corporate Social Responsibility) work and or strategic
                    plans in their sustainability journey.
                    <ul>
                      <li className="list-none">(SDG Goal 1-No poverty)</li>
                      <li className="list-none">
                        (SDG Goal 4- Quality education)
                      </li>
                      <li className="list-none">
                        (SDG Goal 10- Reduced inequalities)
                      </li>
                      <li className="list-none">
                        (SDG Goal 17- Partnerships for the goals)
                      </li>
                    </ul>
                  </div>
                  <h3 className="text-3xl font-bold ">Missions</h3>
                  <ul>
                    <li className="list-none">
                      To provide part time work opportunities/training for
                      career and livelihood sustenance
                    </li>
                    <li className="list-none">
                      To provide drip funding to assist them with daily
                      livelihood incumbrances for a designated interim period.
                    </li>
                    <li className="list-none">
                      To ensue normality with activities to assist integration
                      and be part of a trauma recovery process
                    </li>
                  </ul>
                  <p>
                    SWA will be working with trustworthy partners in the space
                    to ensure that the right assistance is provided to the right
                    parties. This is ensured through 2 beneficiaries whom we
                    have identified to bring forth the synergized missions
                    Symrise and SWA have established.
                  </p>
                  <h3 className="text-3xl font-bold ">
                    Beneficiaries (Channels)
                  </h3>
                  <p>
                    SWA will be working together with 3 established
                    beneficiaries
                  </p>
                  <p>
                    <span className="block font-bold">
                      Singapore Council of Women’s Organisations(SCWO)
                    </span>
                    <span className="font-bold text-swa-1">
                      Star Shelter Project
                    </span>{" "}
                    <br />
                    Collaboration with SWA since 2021. <br />
                    Main provision - To provide a safe refuge for female victims
                    of domestic violence regardless of race and religion and
                    equip them with skills to take ownership of their lives.
                    <br />
                    <a
                      href="https://www.scwo.org.sg/about-us/overview/about-us/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-base font-medium text-rose-500"
                    >
                      Visit SCWO website for details
                    </a>
                  </p>
                  <figure className="flex space-x-2 ">
                    <div className="block w-full overflow-hidden ">
                      <StaticImage
                        src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637222300/rebuildPage/x9aifiq751dy0vbagtnf.jpg"
                        alt="star shelter"
                        // aspectRatio={10 / 8}
                        // objectPosition={"50% 50%"}
                        // objectFit={"contain"}
                      />
                    </div>
                    <div className="block w-full overflow-hidden ">
                      <StaticImage
                        src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637222301/rebuildPage/gp47rz89cg1zwchtsjy0.jpg"
                        alt="star shelter"
                        // aspectRatio={10 / 8}
                        // objectPosition={"50% 50%"}
                        // objectFit={"contain"}
                      />
                    </div>
                  </figure>
                  {/* <figcaption>Star Shelter</figcaption> */}

                  <p>
                    <span className="block font-bold">
                      Singapore Anglican Community Services(SACS)
                    </span>
                    <span className="font-bold text-swa-1">
                      AFC Temporary Refuge
                    </span>{" "}
                    <br />
                    Project to commence in January 2022
                    <br /> Main provision- To provide a safe refuge for female
                    victims of domestic violence regardless of race and religion
                    <br />
                    <a
                      href="https://sacs.org.sg/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-base font-medium text-rose-500"
                    >
                      Visit AFC website for details
                    </a>
                  </p>

                  <figure className="flex space-x-2 ">
                    <StaticImage
                      src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637225425/rebuildPage/lglurij1j4mwhvtwzyiv.png"
                      alt="star shelter"
                    />
                    <StaticImage
                      src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637225420/rebuildPage/v6jjn9u6424nqp50venp.png"
                      alt="star shelter"
                    />
                  </figure>

                  <p>
                    <span className="block font-bold">Casa Raudha Ltd</span>
                    After more than a decade of providing safe accommodation for
                    survivors of domestic violence, Casa Raudha has evolved and
                    cast its net wider in supporting vulnerable families and
                    individuals in combating domestic violence.
                    <br /> Casa Raudha advocates for life free of domestic
                    violence by providing shelter and care for survivors of
                    domestic violence. Additionally, Casa Raudha also conducts
                    programs to raise awareness and works closely with
                    vulnerable families, including the perpetrators towards
                    building resilience.
                    <br />
                    <a
                      href="https://www.casaraudha.org/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-base font-medium text-rose-500"
                    >
                      Visit Casa Raudha website for details
                    </a>
                  </p>
                  {/* <figure className="flex space-x-2 ">
                    <StaticImage
                      src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1639988152/rebuildPage/abat1axawrus3uxfvtai.png"
                      alt="Casa Raudha Ltd"
                    />
                  </figure> */}

                  <h3 className="text-3xl font-bold">Structure of Programme</h3>
                  <p>Funding sreams provisions</p>
                  <h4>Financial Independence</h4>
                  <p>
                    One off funding for victims with low income declarations and
                    qualifications who are shelter residents. It aims to support
                    them after the departure from the shelter as they rebuild
                    their lives and integrate into the society. <br />
                    <br />
                    Disbursed sums for the individuals(Mother and children) to
                    manage control and provide sense of security
                  </p>
                  <h4>Integration to society</h4>
                  <p>
                    One time all-expense paid mid-year excursion to a local
                    places of interest to open their perspective and integrate
                    with educational aspects and experience a regular family
                    life to learn and manage their future expectations in a
                    violent free environment.
                  </p>
                  <h4>Steps to trust</h4>
                  <p>
                    An annual lunch for them to share and learn with others in
                    similar situations and to experience kindness in life. This
                    all expense covered luncheon serves to help them get over
                    their trauma and rebuild trust in people and extend their
                    capabilities when they achieved their bandwidth to
                    contribute.
                  </p>
                  <h4>Skills Development</h4>
                  <p>
                    Skills level development and design programs with Symrise to
                    develop monetizable skills to help develop confidence and
                    working skills for the women to reintegrate into the working
                    community as a healthy individual
                  </p>
                </div>
                <div className="px-4 py-8 mx-auto my-12 border border-gray-300 rounded-lg max-w-7xl sm:px-6">
                  <h2 className="text-2xl font-bold text-gray-900 sm:text-4xl">
                    <span className="block">
                      Is your organisation looking to support and sponsor our
                      initiatives?
                    </span>
                    <span className="block text-swa-1">Let's discuss.</span>
                  </h2>
                  <div className="flex mt-8">
                    <div className="inline-flex rounded-md shadow">
                      <Link
                        to="/forms/contact"
                        className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
                      >
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default rebuild;
